/* Header.css */

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001; /* Ensure it is above the sidebar */
}

.content-container {
    padding-top: 60px; /* Adjust to the height of the header */
}

@media (min-width: 768px) {
    .content-container {
        margin-left: 220px; /* Adjust to the width of the sidebar */
    }
}

@media (max-width: 768px) {
    .content-container {
        margin-left: 0;
    }
}

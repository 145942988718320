/* Sidebar always visible on desktop */
.sidebar {
  width: 250px; /* Adjust as needed */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #343a40; /* Sidebar background color */
  color: #000; /* Set font color to black */
  overflow-y: auto;
  transition: transform 0.3s ease;
  transform: translateX(0); /* Visible on desktop */
}

.custom-loader {
  width:25px;
  height:25px;
  border-radius:80%;
  border:4px solid;
  border-color:#E4E4ED;
  border-right-color: #766DF4;
  animation:s2 1s infinite linear;
}
@keyframes s2 {to{transform: rotate(1turn)}}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #343a40; /* Sidebar background color */
    color: #000; /* Set font color to black */
    transform: translateX(-100%); /* Initially hidden off-screen */
  }

  .sidebar.show {
    transform: translateX(0); /* Slide in when visible */
  }

  .mobile-menu-icon {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
  }
}

@media (min-width: 769px) {
  .mobile-menu-icon {
    display: none; /* Hide the mobile menu icon on desktop */
  }
}

/* Content area adjustment */
.content {
  margin-left: 250px; /* Ensure content is not hidden behind the sidebar */
}

@media (max-width: 768px) {
  .content {
    margin-left: 0; /* No margin on mobile view */
  }
}

/* Card styles */
.card {
  border: none;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 1rem;
  margin-bottom: 1rem;
}

/* Button styles */
.btn-light {
  color: #000;
  background-color: #fff;
  border-color: #ced4da;
}

.btn-primary {
  margin-left: 1rem;
}

/* Flexbox utility classes */
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

/* Offcanvas styles */
.offcanvas {
  width: 250px;
}

/* Mobile Menu Icon */
.mobile-menu-icon .fa-bars {
  color: #007bff; /* Customize the color as needed */
  cursor: pointer;
}
.table-header-row {
  background-color: #e9ecef; /* Professional light gray background */
  font-weight: bold;
}

/* Sidebar.css */

/* General sidebar styles */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); /* Hide sidebar by default */
}

.sidebar.show {
    transform: translateX(0); /* Show sidebar when 'show' class is added */
}

/* Title style */
.sidebar-title {
    margin-bottom: 30px;
    font-weight: bold;
    color: #343a40;
}

/* Navigation styles */
.sidebar-nav {
    flex: 1;
}

.sidebar-link {
    margin-bottom: 10px;
    color: #343a40;
    font-weight: 500;
    text-decoration: none;
}

/* Hide sidebar on mobile view */
@media (min-width: 768px) {
    .sidebar {
        transform: translateX(0); /* Always show sidebar on larger screens */
    }

    .container {
        margin-left: 220px; /* Adjust to the width of the sidebar */
    }
}

@media (max-width: 768px) {
    .container {
        margin-left: 0;
    }
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
}

.sidebar-icon {
    margin-right: 10px;
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
}



.sidebar-link {
    /* Your existing sidebar link styles */
}

.sidebar-divider {
    border: 0;
    border-top: 1px solid #ddd;
    margin: 0.5rem 0;
}

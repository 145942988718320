/* Login.css */

/* Basic styles for the container */
.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f8f9fa;
    overflow: hidden; /* Ensure the backdrop doesn't scroll */
}

/* Backdrop style */
.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1; /* Ensure it sits behind the card */
}

/* Card styles */
.card {
    position: relative;
    z-index: 2; /* Ensure it sits on top of the backdrop */
    max-width: 400px;
    width: 90%;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

/* Logo container */
.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/* Logo style */
.logo {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

/* Header styles */
.main-header,
.sub-header {
    font-size: 1.25rem;
    margin-bottom: 10px;
    text-align: center;
    color: #343a40;
}

.sub-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

/* Alert style */
.alert {
    margin-bottom: 15px;
}

/* Button styles */
.button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
}

/* Footer buttons */
.footer-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

/* Link button style */
.link-button {
    padding: 0;
    font-size: 0.875rem;
}

/* Footer style */
.footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 0.875rem;
}

/* Media Queries for responsiveness */
@media (max-width: 576px) {
    .card {
        padding: 15px;
    }

    .logo {
        width: 80px;
        height: 80px;
    }

    .main-header,
    .sub-header {
        font-size: 1rem;
    }
}
